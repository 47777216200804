import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import COEInsert from './COE/COEInsert';
import CryptoInsert from './Crypto/CryptoInsert';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: 'rgba(16, 192, 198, 0.08)'
  }
}));

const Security = (props) => {
  const { t: translate } = useTranslation();
  const classes = useStyles();
  const previousRoute = props.match.url;
  const pathName = props.location.pathname;
  const history = useHistory();

  const isPageActive = (page) => pathName.search(page) !== -1;

  const handleChangePage = (page) => {
    history.push(`${previousRoute}/${page}`);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item>
          <Button
            color="primary"
            className={isPageActive('insert/coe') ? classes.active : ''}
            onClick={() => handleChangePage('insert/coe')}
          >
            {translate('COE insertion')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={isPageActive('insert/crypto') ? classes.active : ''}
            onClick={() => handleChangePage('insert/crypto')}
          >
            {translate('Crypto insertion')}
          </Button>
        </Grid>
      </Grid>

      <Grid>
        <Switch>
          <Route path={previousRoute + '/insert/coe'} component={COEInsert} />
          <Route path={previousRoute + '/insert/crypto'} component={CryptoInsert} />
          <Redirect to={previousRoute + '/insert/coe'} />
        </Switch>
      </Grid>
    </>
  );
};

export default Security;
